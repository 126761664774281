'use strict'

angular
	.module('cb.core.session-interceptor')
	.factory('SessionInterceptor', ["$rootScope", "NotificationService", "$q", "$window", "$location", function ($rootScope, NotificationService, $q, $window, $location) {
		return {
			request: function (config) {
				config.headers = config.headers || {};
				if ($window.localStorage.cbToken && !config.url.includes('postcodes.io')) {
					config.headers.Authorization = 'Bearer ' + $window.localStorage.cbToken;
				}
				return config;
			},
			response: function (response) {
				return response || $q.when(response);
			},
			responseError: function (rejection) {

				switch (rejection.status) {
					case 401:
						NotificationService.alert('Unauthorized Response!', 'There seemed to be a problem with your request.', 'error', {});
						break;
					case 404:
						//$rootScope.onLineFlag = false;
						break
					case 500:
						NotificationService.alert()
						NotificationService.alert('Internal Error!', 'Something went wrong.', 'warning', {});
						break;
					default:
						
						if (rejection.data.ModelState && Object.keys(rejection.data.ModelState)) {
							for (var k in rejection.data.ModelState) {
								NotificationService.alert(k, rejection.data.ModelState[k], 'error', {});
							}
						}
						break;
				}

				return $q.reject(rejection);
			}
		};
	}]);
